/*! _layout-utils.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Bulkit utils
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Resets and base setup
1. Back to top button
2. Progress bars
3. Input placeholders
4. Window scrollbar
5. Demo link colors
=============================================================================
***/

/* ==========================================================================
0. Resets and base setup
========================================================================== */

body {
    font-family: -apple-system,"Nunito",BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    overflow: hidden !important;
}

a, a:active, i, i:active, div, div:active, div:focus, span:focus, span:active, {
    outline: none !important;
}

.button.is-active, .button:active, .button:focus {
    box-shadow: none !important
}

section:focus {
    outline: none !important;
}

/* nunito-200 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/text/nunito/nunito-v10-latin-200.eot'); /* IE9 Compat Modes */
    src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'),
    url('../fonts/text/nunito/nunito-v10-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-200.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-200.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-200italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 200;
    src: url('../fonts/text/nunito/nunito-v10-latin-200italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito ExtraLight Italic'), local('Nunito-ExtraLightItalic'),
    url('../fonts/text/nunito/nunito-v10-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-200italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-200italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-300italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/text/nunito/nunito-v10-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Light Italic'), local('Nunito-LightItalic'),
    url('../fonts/text/nunito/nunito-v10-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-300italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-300 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/text/nunito/nunito-v10-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Nunito Light'), local('Nunito-Light'),
    url('../fonts/text/nunito/nunito-v10-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-300.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/text/nunito/nunito-v10-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Italic'), local('Nunito-Italic'),
    url('../fonts/text/nunito/nunito-v10-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-regular - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/text/nunito/nunito-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Nunito Regular'), local('Nunito-Regular'),
    url('../fonts/text/nunito/nunito-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-600 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/text/nunito/nunito-v10-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
    url('../fonts/text/nunito/nunito-v10-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-600.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-600italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/text/nunito/nunito-v10-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito SemiBold Italic'), local('Nunito-SemiBoldItalic'),
    url('../fonts/text/nunito/nunito-v10-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-600italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-700 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/text/nunito/nunito-v10-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Nunito Bold'), local('Nunito-Bold'),
    url('../fonts/text/nunito/nunito-v10-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-700italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/text/nunito/nunito-v10-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Bold Italic'), local('Nunito-BoldItalic'),
    url('../fonts/text/nunito/nunito-v10-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-700italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-800 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/text/nunito/nunito-v10-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'),
    url('../fonts/text/nunito/nunito-v10-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-800.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-800.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-900 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/text/nunito/nunito-v10-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Nunito Black'), local('Nunito-Black'),
    url('../fonts/text/nunito/nunito-v10-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-900.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-900.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-800italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/text/nunito/nunito-v10-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito ExtraBold Italic'), local('Nunito-ExtraBoldItalic'),
    url('../fonts/text/nunito/nunito-v10-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-800italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-800italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-900italic - latin */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/text/nunito/nunito-v10-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local('Nunito Black Italic'), local('Nunito-BlackItalic'),
    url('../fonts/text/nunito/nunito-v10-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/text/nunito/nunito-v10-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-900italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/text/nunito/nunito-v10-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/text/nunito/nunito-v10-latin-900italic.svg#Nunito') format('svg'); /* Legacy iOS */
}



/* ==========================================================================
1. Back to top button
========================================================================== */

#backtotop {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 25px;
    margin: 0 25px 0 0;
    z-index: 9999;
    transition: 0.35s;
    transform: scale(0.7);
    transition: all 0.5s;
}

#backtotop.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);

}

#backtotop.visible a:hover {
    outline: none;
    opacity: 0.9;
    background: $secondary;
}

#backtotop a {
    outline: none;
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 46px;
    height: 46px;
    background-color: $medium-grey;
    opacity: 1;
    transition: all 0.3s;
    border-radius: 50%;
    text-align: center;
    font-size: 26px
}

body #backtotop a {
    outline: none;
    color: #fff;
}

#backtotop a:after {
    outline: none;
    content: "\f106";
    font-family: "FontAwesome";
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
}


/* ==========================================================================
2. Progress bars
========================================================================== */

.progress {
    width: 100% !important;
}

.progress.primary::-webkit-progress-value {
    background-color: $primary !important;
}

.progress.secondary::-moz-progress-bar {
    background-color: $secondary !important;
}


/* ==========================================================================
3. Input placeholders
========================================================================== */

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder;
}
:-ms-input-placeholder { /* IE 10+ */
    color: $placeholder;
}
:-moz-placeholder { /* Firefox 18- */
    color: $placeholder;
}


/* ==========================================================================
4. Window scrollbar
========================================================================== */

*::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
    width: 10px;
}
*::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0,0,0,0.2)
}